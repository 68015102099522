import { createMuiTheme } from "@material-ui/core/styles";

const CustomDark = createMuiTheme({
    custom: {
        icon: "#fff",
        loadingBar: "#fff",
    },
    palette: {
        type: "dark",
        primary: {
            main: "#6c808d",
        },
        background: {
            default: "#1b2023",
            paper: "#1b2023",
        },
    },
});

export default CustomDark;
